import * as React from 'react';
import { useServices } from "inject-typesafe-react";
import { AppServices } from "../../configure/configureServices";
import { CacheOptions } from '../cacheInRedux';

/**
 * Use state stored in the redux cache via CacheService.
 * @param id
 */
export function useCache<T = any>(key: string): [T | null, (value: T, options?: CacheOptions) => void] {
    const cacheService = useServices((services: AppServices) => services.cacheService());

    const setState = React.useCallback((value: T, options?: CacheOptions) => {
        cacheService.storeInCache(key, value, options);
    }, [cacheService, key]);

    return [cacheService.readFromCache(key), setState];
}