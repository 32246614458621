import { createSlice } from '@reduxjs/toolkit';

export interface CacheState {
    items: { [key: string]: CacheItem },
}

export interface CacheItem {
    data: any,
    expireAt?: Date,
    expireOnEvents: Array<string>
}

export interface StoreInCacheAction {
    type: string,
    payload: {
        id: string,
        data: any,
        expireAt?: Date,
        expireOnEvents: Array<string>
    }
}

export interface RemoveFromCacheAction {
    type: string,
    payload: {
        id: string
    }
}

export interface RaiseExpireEventsAction {
    type: string,
    payload: {
        events: Array<string>
    }
}

/**
 * General cache for storing name value pairs in redux that don't need their own slices or actions. 
 */
const cacheSlice = createSlice({
    name: 'currentUserRoles',
    initialState: { items: {} } as CacheState,
    reducers: {
        /**
         * Store an item in the cache.
         * @param state
         * @param action
         */
        storeInCache(state, action: StoreInCacheAction) {
            const { id, data, expireAt, expireOnEvents } = action.payload;
            state.items = {
                ...state.items,
                [id]: { data, expireAt, expireOnEvents }
            };
        },

        /**
         * Remove an item from the cache.
         * @param state
         * @param action
         */
        removeFromCache(state, action: RemoveFromCacheAction) {
            const { id } = action.payload;
            delete state.items[id];
        },

        /**
         * Clear the entire cache.
         * @param state
         * @param action
         */
        clearCache(state) {
            state.items = {};
        },

        /**
         * Remove an item from the cache.
         * @param state
         * @param action
         */
        raiseExpireEvents(state, action: RaiseExpireEventsAction) {
            const { events } = action.payload;
            for (const id in state.items) {
                const item = state.items[id];
                if (!item.expireOnEvents) {
                    continue;
                }
                // If any of the events being raised effect this item then exclude it from the results so it gets removed from the cache.
                const hasEvent = (item: CacheItem, event: string) => item.expireOnEvents.filter(it => it === event).length ? true : false;
                for (let i = 0; i < events.length; ++i) {
                    var event = events[i];
                    if (hasEvent(item, event)) {
                        delete state.items[id];
                    }
                }
            }
        },
    }
});

export const { storeInCache, removeFromCache, clearCache, raiseExpireEvents } = cacheSlice.actions;
export const cacheReducer = cacheSlice.reducer;
