import { RouteEntry } from "../../shared/routes";
import { IdentityRoles } from "../../configure/security/IdentityRoles";
import { SchoolPhasesList } from "./SchoolPhasesList";
import { EditSchoolPhase, CreateSchoolPhase } from "./EditSchoolPhase";
import { DeleteSchoolPhase } from "./DeleteSchoolPhase";

export const schoolPhaseRoutes: Array<RouteEntry> = [
    { path: '/administration/school-phases', exact: true, component: SchoolPhasesList, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/school-phases/edit/:id', component: EditSchoolPhase, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/school-phases/add', component: CreateSchoolPhase, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/school-phases/delete/:id', component: DeleteSchoolPhase, authorize: true, requireRole: IdentityRoles.Administration },
];
