import './mainContainer.scss'; 

import * as React from 'react';
import { Container, ContainerProps } from 'reactstrap';

export interface MainContainerProps extends ContainerProps {
}

/**
 * Input that is hooked up for searching as the user types, by introducing an appropriate delay before raising the onSearch event.
 */
export const MainContainer = (props: MainContainerProps) => {
    const { className, children, ...rest } = props;

    return (
        <Container className={`main-container ${className ?? ''}`} {...rest}>
            {children}
        </Container>
    );
};