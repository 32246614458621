import './accountBackground.scss';

import * as React from 'react';
import { BackgroundProps, Background } from '../shared/Background';

export interface AccountBackgroundProps extends BackgroundProps {
}

export const AccountBackground = (props: AccountBackgroundProps) => {
    const { className, ...rest } = props;

    return (<Background className={`account-background ${className ?? ''}`} {...rest} />);
};