import * as React from 'react';
//import { AppFooter } from '@coreui/react';

export const Footer = () => {
    //return (
    //    <AppFooter>
    //        <div className="footer-brand">
    //        </div>
    //    </AppFooter>
    //);

    return (<></>);
};

