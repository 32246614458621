import { RouteEntry } from "../../shared/routes";
import { IdentityRoles } from "../../configure/security/IdentityRoles";
import { SchoolTypesList } from "./SchoolTypesList";
import { EditSchoolType, CreateSchoolType } from "./EditSchoolType";
import { DeleteSchoolType } from "./DeleteSchoolType";

export const schoolTypeRoutes: Array<RouteEntry> = [
    { path: '/administration/school-types', exact: true, component: SchoolTypesList, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/school-types/edit/:id', component: EditSchoolType, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/school-types/add', component: CreateSchoolType, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/school-types/delete/:id', component: DeleteSchoolType, authorize: true, requireRole: IdentityRoles.Administration },
];
