import './sidebarRequired.scss';

import * as React from 'react';
import { Helmet } from 'react-helmet-async';

/**
 * Component that shows the sidebr if it is required/recommended for the screensize.
 */
export const SidebarRequired = () => {
    return (
        <>
            <Helmet>
                <body data-sidebar-required="true" />
            </Helmet>
        </>
    );
};
