import * as React from 'react';
import { Input, InputGroup, InputGroupAddon, Button, InputProps } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

export interface SearchInputProps extends InputProps {
}

/**
 * Input that is hooked up for searching as the user types, by introducing an appropriate delay before raising the onSearch event.
 */
export const SearchInput = (props: SearchInputProps) => {
    const { type, placeholder, ...rest } = props;

    const { t } = useTranslation();

    return (
        <InputGroup className="search-input">
            <Input type={type ?? 'search'} placeholder={ placeholder ?? t('searchInput.placeholder', 'Search') } {...rest} />
            <InputGroupAddon addonType="append">
                <Button>
                    <FontAwesomeIcon icon="search" />
                    <span className="sr-only">{t('searchInput.searchButton', 'Search')}</span>
                </Button>
            </InputGroupAddon>
        </InputGroup>
    );
};